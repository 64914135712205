import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import PageContainer from "./common/pageContainer";
import InnerPageContainer from "./common/innerPageContainer";
// import heroCover from "../images/hero/heroCover.svg";
// import heroCoverDesktop from "../images/hero/heroCoverDesktop.svg";
import heroMobile from "../images/hero/heroMobile.json";
import heroDesktop from "../images/hero/heroDesktop.json";
import lottieLight from "lottie-web/build/player/lottie_light";
import { useEmblaCarousel } from "embla-carousel/react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import Name from "./common/name";
// import { BsArrowDown } from "react-icons/bs";

const CONTENT = {
  heroTitle: "28 Stanley",
  heroImage: "hero.jpg",
  mainTitle: "28 Stanley",
  certificationTitle: "Certifications",
  description: (
    <>
      <Name /> is a 31-floor building located right in the heart of Central,
      Hong Kong, positioned on a street with a plethora of authentic restaurants
      and cafes, most notably the Luk Yu Tea House (陸羽茶室), a famous colonial
      style dim sum restaurant with history that dates back to 1933. <Name />{" "}
      offers state-of-the-art infrastructure and facilities, constituting an
      excellent venue for both business and leisure. <Name /> sparks a new era
      as an icon in modern Hong Kong.
    </>
  ),
  scrollText: "Scroll to know more",
};

const Container = styled.section`
  padding-bottom: 5rem;

  @media (min-width: 992px) {
    padding-bottom: 8rem;
  }

  //@media (min-width: 1200px) {
  //  padding-bottom: 10rem;
  //}
  //
  //@media (min-width: 1400px) {
  //  padding-bottom: 12rem;
  //}
`;

const HeroImageContainer = styled(InnerPageContainer)`
  position: relative;
  overflow: hidden;
  cursor: pointer;
`;

const HeroContent = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @media (min-width: 576px) {
    left: unset;
    top: unset;
    transform: none;
    right: 0;
    bottom: -0.7rem;
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }

  @media (min-width: 768px) {
    bottom: -0.95rem;
  }

  @media (min-width: 992px) {
    bottom: -1.25rem;
  }

  @media (min-width: 1200px) {
    bottom: -1.4rem;
  }
`;

// const HeroArrowContainer = styled.div`
//   width: 3rem;
//   height: 3rem;
//   border: 2px solid #fff;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border-radius: 99px;
//   margin: 0.5rem auto 0;
//
//   @media (min-width: 576px) {
//     margin: 0 0 0 2rem;
//     width: 3.2rem;
//     height: 3.2rem;
//   }
//
//   @media (min-width: 768px) {
//     margin-left: 4rem;
//     width: 4.2rem;
//     height: 4.2rem;
//   }
//
//   @media (min-width: 992px) {
//     width: 5.5rem;
//     height: 5.5rem;
//   }
//
//   @media (min-width: 1200px) {
//     width: 6.25rem;
//     height: 6.25rem;
//   }
// `;
//
// const HeroArrow = styled(BsArrowDown)`
//   display: block;
//   color: #fff;
//   width: 1.5rem;
//   height: 1.5rem;
//
//   @media (min-width: 576px) {
//     width: 2rem;
//     height: 2rem;
//   }
//
//   @media (min-width: 768px) {
//     width: 2.5rem;
//     height: 2.5rem;
//   }
//
//   @media (min-width: 992px) {
//     width: 3.5rem;
//     height: 3.5rem;
//   }
//
//   @media (min-width: 1200px) {
//     width: 4.5rem;
//     height: 4.5rem;
//   }
// `;

const HeroLogos = styled.div`
    margin-top: 1rem;
    display: flex;
    justify-content: center;

    @media (min-width: 576px) {
      justify-content: flex-start;
    }
`;

const HeroTitle = styled.h1`
  margin: 0;
  line-height: 1;
  color: #fff;
  font-size: 3rem;
  width: 16rem;
  text-align: center;

  @media (min-width: 576px) {
    width: unset;
    font-size: 4.5rem;
  }

  @media (min-width: 768px) {
    font-size: 6rem;
  }

  @media (min-width: 992px) {
    font-size: 8rem;
  }

  @media (min-width: 1200px) {
    font-size: 9rem;
  }
`;

const ContentContainer = styled.div`
  max-width: 1035px;
  margin: 4rem auto 0;

  @media (min-width: 768px) {
    margin-top: 5rem;
  }

  @media (min-width: 992px) {
    margin-top: 8rem;
  }

  @media (min-width: 1200px) {
    margin-top: 10rem;
  }

  @media (min-width: 1400px) {
    margin-top: 12rem;
  }
`;

const ContentInnerContainer = styled.div`
  margin-left: auto;
  max-width: 520px;
`;

const MainTitle = styled.h2`
  font-size: 3rem;
  pointer-events: none;
  color: ${(props) => props.theme.darkBody};

  @media (min-width: 768px) {
    font-size: 4rem;
  }

  @media (min-width: 992px) {
    font-size: 4.5rem;
  }
`;

const Description = styled.p`
  margin-top: 1rem;
  color: ${(props) => props.theme.darkBody};
`;

const HeroImage = styled.div`
  @media (min-width: 576px) {
    display: none;
  }
`;

const DesktopHeroImage = styled.div`
  display: none;

  @media (min-width: 576px) {
    display: block;
  }
`;

const HeroCover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 250ms ease-in-out;
  pointer-events: none;

  @media (min-width: 576px) {
    display: none;
  }
`;

const HeroCoverDesktop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 250ms ease-in-out;
  display: none;
  pointer-events: none;

  @media (min-width: 576px) {
    display: block;
  }
`;

const Embla = styled.div`
  position: relative;
  overflow: hidden;
  z-index: 1;
`;

const EmblaContainer = styled.div`
  display: flex;
`;

const EmblaSlide = styled.div`
  flex: 0 0 100%;
`;

const SlideContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 132.26%;

  @media (min-width: 576px) {
    padding-top: 67.1%;
  }
`;

const SliderInnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const ArrowContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 2rem;
  cursor: pointer;
`;

const LeftArrowContainer = styled(ArrowContainer)`
  left: 0;
  padding-left: 0;
`;

const LeftArrow = styled(BsChevronLeft)`
  color: #fff;
  display: block;
  width: 2rem;
  height: 2rem;

  @media (min-width: 768px) {
    width: 3rem;
    height: 3rem;
  }
`;

const RightArrowContainer = styled(ArrowContainer)`
  right: 0;
  padding-right: 0;
`;
const RightArrow = styled(BsChevronRight)`
  color: #fff;
  display: block;
  width: 2rem;
  height: 2rem;

  @media (min-width: 768px) {
    width: 3rem;
    height: 3rem;
  }
`;

// markup
const Hero = () => {
  const [emblaRef, embla] = useEmblaCarousel();

  const coverRef = useRef(null);
  const coverDesktopRef = useRef(null);
  const animationRef = useRef(null);
  // const [coverAnimation, setCoverAnimation] = useState(true);
  // const [coverDesktopAnimation, setCoverDesktopAnimation] = useState(true);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

  useEffect(() => {
    const animation = lottieLight.loadAnimation({
      container: coverRef.current, // the dom element that will contain the animation
      // loop: false,
      autoplay: false,
      animationData: heroMobile,
    });

    animation.setSpeed(0.6);

    animation.onComplete = () => {
      // animation.setDirection(-1)
      // setCoverAnimation(false);
    };

    const animationDesktop = lottieLight.loadAnimation({
      container: coverDesktopRef.current, // the dom element that will contain the animation
      // loop: false,
      autoplay: false,
      animationData: heroDesktop,
    });

    animationDesktop.setSpeed(0.6);

    setTimeout(() => {
      animation.play();
      animationDesktop.play();
    }, 500);

    animationRef.current = { animation, animationDesktop };

    return () => {
      animation.destroy();
      animationDesktop.destroy();
    };
  }, []);

  return (
    <Container>
      <HeroImageContainer>
        <Embla ref={emblaRef}>
          <EmblaContainer>
            <EmblaSlide>
              <SlideContainer>
                <SliderInnerContainer>
                  <HeroCover ref={coverRef} />
                  <HeroCoverDesktop ref={coverDesktopRef} />
                  <RightArrowContainer
                    onClick={() => {
                      scrollNext();
                    }}
                  >
                    <RightArrow />
                  </RightArrowContainer>
                </SliderInnerContainer>
              </SlideContainer>
            </EmblaSlide>
            <EmblaSlide>
              <SlideContainer>
                <SliderInnerContainer>
                  <HeroImage>
                    <StaticImage
                        src={`../images/hero/hero.jpg`}
                        alt={"Building"}
                        layout="fullWidth"
                        loading="eager"
                        aspectRatio={375 / 496}
                    />
                  </HeroImage>
                  <DesktopHeroImage>
                    <StaticImage
                        src={`../images/hero/hero.jpg`}
                        alt={"Building"}
                        layout="fullWidth"
                        loading="eager"
                        aspectRatio={1035 / 695}
                    />
                  </DesktopHeroImage>
                  <HeroContent>
                    <HeroTitle>{CONTENT.heroTitle}</HeroTitle>
                    {/*<HeroArrowContainer>*/}
                    {/*  <HeroArrow />*/}
                    {/*</HeroArrowContainer>*/}
                  </HeroContent>
                  <LeftArrowContainer
                      onClick={() => {
                        scrollPrev();
                      }}
                  >
                    <LeftArrow />
                  </LeftArrowContainer>
                  <RightArrowContainer
                      onClick={() => {
                        scrollNext();
                      }}
                  >
                    <RightArrow />
                  </RightArrowContainer>
                </SliderInnerContainer>
              </SlideContainer>
            </EmblaSlide>
            <EmblaSlide>
              <SlideContainer>
                <SliderInnerContainer>

                  <HeroImage>
                    <StaticImage
                        src={`../images/features/HongKong-28Stanley-MemberLounge_Gallery.webp`}
                        alt={"Building"}
                        layout="fullWidth"
                        loading="eager"
                        aspectRatio={375 / 496}
                    />
                  </HeroImage>
                  <DesktopHeroImage>
                    <StaticImage
                        src={`../images/features/HongKong-28Stanley-MemberLounge_Gallery.webp`}
                        alt={"Building"}
                        layout="fullWidth"
                        loading="eager"
                        aspectRatio={1035 / 695}
                    />
                  </DesktopHeroImage>
                  <HeroContent>
                    <HeroTitle>{CONTENT.heroTitle}</HeroTitle>
                    {/*<HeroArrowContainer>*/}
                    {/*  <HeroArrow />*/}
                    {/*</HeroArrowContainer>*/}
                  </HeroContent>
                  <LeftArrowContainer
                      onClick={() => {
                        scrollPrev();
                      }}
                  >
                    <LeftArrow />
                  </LeftArrowContainer>
                  <RightArrowContainer
                      onClick={() => {
                        scrollNext();
                      }}
                  >
                    <RightArrow />
                  </RightArrowContainer>
                </SliderInnerContainer>
              </SlideContainer>
            </EmblaSlide>
            <EmblaSlide>
              <SlideContainer>
                <SliderInnerContainer>
                  <HeroImage>
                    <StaticImage
                        src={`../images/gallery/HongKong-28Stanley-EventSpace_Gallery.webp`}
                        alt={"Building"}
                        layout="fullWidth"
                        loading="eager"
                        aspectRatio={375 / 496}
                    />
                  </HeroImage>
                  <DesktopHeroImage>
                    <StaticImage
                        src={`../images/gallery/HongKong-28Stanley-EventSpace_Gallery.webp`}
                        alt={"Building"}
                        layout="fullWidth"
                        loading="eager"
                        aspectRatio={1035 / 695}
                    />
                  </DesktopHeroImage>
                  <HeroContent>
                    <HeroTitle>{CONTENT.heroTitle}</HeroTitle>
                    {/*<HeroArrowContainer>*/}
                    {/*  <HeroArrow />*/}
                    {/*</HeroArrowContainer>*/}
                  </HeroContent>
                  <LeftArrowContainer
                      onClick={() => {
                        scrollPrev();
                      }}
                  >
                    <LeftArrow />
                  </LeftArrowContainer>
                </SliderInnerContainer>
              </SlideContainer>
            </EmblaSlide>
          </EmblaContainer>
        </Embla>
      </HeroImageContainer>
      <PageContainer>
        <InnerPageContainer id="about">
          <ContentContainer>
            <ContentInnerContainer>
              <MainTitle>{CONTENT.mainTitle}</MainTitle>
              <Description>{CONTENT.description}</Description>
              <HeroLogos>
                <StaticImage
                    src={`../images/hero/LEED_2024_GOLD.png`}
                    alt={"LEED Gold Certified"}
                    width={150}
                    height={150}
                    loading="eager"
                />
              </HeroLogos>
            </ContentInnerContainer>
          </ContentContainer>
        </InnerPageContainer>
      </PageContainer>
    </Container>
  );
};

export default Hero;
