import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import PageContainer from "./common/pageContainer";
import InnerPageContainer from "./common/innerPageContainer";
import shape28 from "../images/shape28.svg";

import Feature from "./feature";
import Name from "./common/name";

const CONTENT = {
  mainTitle: "Features",
  description: (
    <>
      <Name /> is classified as a “Grade A” building, the highest rating of
      aesthetics and functionality, making it perfect for prestigious offices &
      fine-dining restaurants.
    </>
  ),
  features: [
    {
      background: "#33504A",
      title: "Office Space.",
      description: (
        <>
          The majority of <Name /> is devised and specialised to serve as office
          areas providing spacious rooms where companies can foster innovation,
          grow and excel.
        </>
      ),
    },
    {
      title: "Fine Dining.",
      description: (
        <>
          Fitted with large double-glazed glass windows, tall ceilings, well-lit
          interiors and spacious rooms, <Name /> offers a prime environment to
          give fine dining customers an unforgettable experience.
        </>
      ),
    },
    {
      background: "#D0D3D4",
      title: "Sea View.",
      description: (
        <>
          Positioned in the focal point of central, <Name /> features a
          beautiful panoramic seaview of victoria harbour and the ferry pier,
          creating an attractive selling point for any restaurant or workspace.
        </>
      ),
    },
  ],
};

const Container = styled.section`
  padding-bottom: 4rem;

  @media (min-width: 576px) {
    padding-bottom: 0;
  }
`;

const IntroContainer = styled.div`
  background: ${(props) => props.theme.primaryGray};
  padding-top: 5rem;
  padding-bottom: 3rem;

  @media (min-width: 992px) {
    padding-top: 8rem;
  }

  //@media (min-width: 1200px) {
  //  padding-top: 10rem;
  //}
  //
  //@media (min-width: 1400px) {
  //  padding-top: 12rem;
  //}
`;

const IntroInnerContainer = styled(InnerPageContainer)``;

const IntroContentContainer = styled.div`
  //width: 50%;
  //margin-left: auto;

  max-width: 520px;
`;

const IntroSideImage = styled.img`
  display: block;
  margin-left: auto;
  margin-top: 2rem;
  width: 5rem;

  @media (min-width: 992px) {
    width: 8rem;
  }

  @media (min-width: 1200px) {
    width: 10rem;
  }
`;

const MainTitle = styled.h2`
  font-size: 3rem;
  // color: ${(props) => props.theme.lightPrimary};

  @media (min-width: 768px) {
    font-size: 4rem;
  }

  @media (min-width: 992px) {
    font-size: 4.5rem;
  }
`;

const Description = styled.p`
  margin-top: 1rem;
`;

const FeaturesContainer = styled.div``;

const FooterContainer = styled.div`
  @media (min-width: 576px) {
    display: none;
  }
`;

const FooterSideImage = styled.img`
  display: block;
  margin-top: 3rem;
  margin-left: auto;
  width: 5rem;
`;

// markup
const Features = () => {
  const images = [
    {
      main: (
        <StaticImage
          src={`../images/features/HongKong-28Stanley-MemberLounge_Gallery.webp`}
          alt={"Office Space"}
          placeholder="blurred"
          layout="fullWidth"
          loading="eager"
          aspectRatio={4 / 3}
        />
      ),
      aside: (
        <StaticImage
          src={`../images/features/feature1Aside.jpg`}
          alt={"Office Space"}
          placeholder="blurred"
          layout="fullWidth"
          loading="eager"
          aspectRatio={3 / 4}
        />
      ),
    },
    {
      main: (
        <StaticImage
          src={`../images/features/feature2Main.jpg`}
          alt={"Fine Dining"}
          placeholder="blurred"
          layout="fullWidth"
          loading="eager"
          aspectRatio={16 / 9}
        />
      ),
      aside: (
        <StaticImage
          src={`../images/features/feature2Aside.jpg`}
          alt={"Fine Dining"}
          placeholder="blurred"
          layout="fullWidth"
          loading="eager"
          aspectRatio={3 / 4}
        />
      ),
    },
    {
      main: (
        <StaticImage
          src={`../images/features/feature3Main.jpg`}
          alt={"Sea View"}
          placeholder="blurred"
          layout="fullWidth"
          loading="eager"
          aspectRatio={16 / 9}
        />
      ),
      aside: (
        <StaticImage
          src={`../images/features/feature3Aside.jpg`}
          alt={"Sea View"}
          placeholder="blurred"
          layout="fullWidth"
          loading="eager"
          aspectRatio={3 / 4}
        />
      ),
    },
  ];

  return (
    <Container id="features">
      <IntroContainer>
        <PageContainer>
          <IntroInnerContainer>
            <IntroContentContainer>
              <MainTitle>{CONTENT.mainTitle}</MainTitle>
              <Description>{CONTENT.description}</Description>
            </IntroContentContainer>
            <IntroSideImage src={shape28} />
          </IntroInnerContainer>
        </PageContainer>
      </IntroContainer>
      <FeaturesContainer>
        {CONTENT.features.map((feature, index) => {
          return <Feature key={index} data={feature} images={images[index]} />;
        })}
      </FeaturesContainer>
      <FooterContainer>
        <PageContainer>
          <FooterSideImage src={shape28} />
        </PageContainer>
      </FooterContainer>
    </Container>
  );
};

export default Features;
